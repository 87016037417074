
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import List from "@/components/notice/List.vue";
import ListItem from "@/components/notice/ListItem.vue";
import LoadingIcon from "icons/Loading.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default defineComponent({
  name: "Notice",
  components: { BaseHeader2, List, ListItem, LoadingIcon, InfiniteLoading },
  data() {
    return {
      infiniteId: +new Date(),
      page: 1,
      noticeList: [] as Array<{
        id: number;
        title: string;
        is_fixed: boolean;
        tstamp: string;
      }>,
    };
  },
  methods: {
    infiniteHandler($state: any) {
      this.load()
        .then((res: any) => {
          $state.loaded();
          if (this.noticeList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.error();
        });
    },
    load() {
      return this.$axios
        .get("/board/notice", {
          params: {
            page: this.page,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (res.length > 0) {
            if (this.page == 1) {
              this.noticeList.splice(0, this.noticeList.length);
              this.noticeList.push(...res);
            } else {
              this.noticeList.push(...res);
            }
          }
          return result;
        });
    },
  },
});
